<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        问题反馈
      </template>
      <template #input>
        <span class="tag">反馈时间段: </span>
        <a-date-picker
          @change="changeStartTime"
          format='YYYY-MM-DD'
          style='width:200px'
          placeholder="起始时间"
          v-model="firstTime"
        />
        <a-date-picker
          @change="changeEndTime"
          format='YYYY-MM-DD'
          style='width:240px'
          placeholder="结束时间"
          v-model="secondTime"
        />
        <a-input
          placeholder="请输入关键字"
          v-model="keyWord"
          allowClear
        />
        <a-button
          type="primary"
          class="btn"
          @click='onSearch()'
        >搜索</a-button>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-table
        class="table-template"
        :rowKey="item=>item.problemId"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total:total,
          current:pageNumber,  
          defaultPageSize:pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
          return `共${total}条`}} ">
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <template
          slot="operation"
          slot-scope="item, row"
        >
            <span class="blueText" @click="toDetail(row.status,row.problemId)">{{row.status==0?"回复":"详情"}}</span>
            <span class="blueText" style="margin-left: 10px;" @click="opencStatusDialog(row)">修改状态</span>
        </template>
      </a-table>
    </div>

    <!-- 修改状态 -->
    <a-modal v-model="cStatusDialog" :confirmLoading="modelLoading" align="center" title="修改状态" @ok="changeStatus">
      <div class="option modelInput">
        <p>处理人：</p>
        <a-input
          placeholder="请输入处理人"
          v-model="modelForm.processor"
          allowClear
        />
      </div>
      <div class="option">
        <p>处理进度：</p>
        <a-textarea
          v-model="modelForm.processSchedule"
          placeholder="请输入处理进度"
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    width: 100,
    dataIndex: "name",
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "问题描述",
    align: "center",
    dataIndex: "content",
  },
  {
    title: "处理人",
    align: "center",
    dataIndex: "processor",
  },
  {
    title: "处理进度",
    align: "center",
    width: 400,
    dataIndex: "processSchedule",
  },
  {
    title: "时间",
    align: "center",
    dataIndex: "backTime",
  },
  {
    title: "操作",
    align: "center",
    width: 140,
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
import moment from "moment";
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      keyWord: "",
      firstTime: null,
      secondTime: null,
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      loading: false,
      detail: false,
      isReadonly: false,

      // 修改状态弹框字段
      cStatusDialog: false,
      modelLoading: false,
      modelForm: {
        problemId: "", // 问题id
        processSchedule: "", // 	处理进度
        processor: "" // 	处理人
      }
    };
  },
  // 事件处理器
  methods: {
    moment,
    // 打开修改状态状态
    opencStatusDialog(e){
      this.modelForm = {
        problemId: e.problemId, // 问题id
        processSchedule: e.processSchedule, // 	处理进度
        processor: e.processor // 	处理人
      }
      this.cStatusDialog = true;
    },
    changeStatus() {
      if(!this.modelForm.problemId) {
        return this.$message.warning('problemId参数丢失！');
      }else if(!this.modelForm.processor) {
        return this.$message.warning('请输入处理人！');
      }else if(!this.modelForm.processSchedule) {
        return this.$message.warning('请输入处理进度！');
      }

      this.modelLoading = true;

      this.$ajax({
        url: '/hxclass-management/problem/manageProcessor',
        method: 'POST',
        params: this.modelForm
      }).then(res=>{
        this.modelLoading = false;
        this.cStatusDialog = false;
        this.modelForm = {
          problemId: "", // 问题id
          processSchedule: "", // 	处理进度
          processor: "" // 	处理人
        }
        this.getManageList();
      })
    },

    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getManageList();
    },
    onSearch() {
      this.pageNumber = 1;
      this.getManageList();
    },
    changeStartTime(x, s) {
      this.firstTime = s;
    },
    changeEndTime(x, s) {
      this.secondTime = s;
    },
    // 输入备注触发
    // onRemarksCheck(e) {
    // console.log(e);
    //   // 防抖
    //   // clearTimeout(this.tiemID);
    //   // this.tiemID = setTimeout(() => {
      // console.log(e)
    //   // }, 1500);
    // },
     toDetail(operation, id) {
        this.$router.push(
          '/admin/MessageManage/replyFeedback?' + 'operation=' + operation + '&id=' + id
        )
      },
    
    getManageList() {
      this.loading = true;
      this.$ajax({
        // method: "get",
        url: "/hxclass-management/problem/manage",
        params: {
          keyWord: this.keyWord,
          firstTime: this.firstTime == null?'':this.firstTime,
          secondTime: this.secondTime == null?'':this.secondTime,
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
        },
      })
        .then((res) => {
          this.loading = false;
          if (res.code == 200 && res.success) {
            this.total = res.data.total;
            this.tableData = res.data.records;
          } else {
            this.tableData = [];
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error) {
            this.$message.warning("系统错误");
          }
        });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getManageList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin: 0 24px 14px 0;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
  .picture {
    width: 50px;
  }
  .remarks {
    border: none;
  }
}
.all_content_box {
  textarea.ant-input {
    width: 458px;
    height: 100px;
  }
  .all_left_name {
    width: 88px;
    // text-align: right;
  }
  .long {
    width: 458px;
  }
  .short {
    width: 193px;
  }

  .all_content_box {
    textarea.ant-input {
      width: 458px;
      height: 100px;
    }

    .all_left_name {
      width: 88px;
      // text-align: right;
    }

    .long {
      width: 458px;
    }

    .photo {
      width: 120px;
    }

  }

  /deep/.ant-modal-header {
    padding: 16px 24px 0px;
    border-bottom: none;
  }

  /deep/.ant-modal-title {
    color: #000000;
  }

  /deep/.ant-modal-footer {
    border-top: none;
  }

  /deep/ .ant-modal-body {
    padding: 24px 24px 14px;
  }
}
.option{
  display: flex;
  p{
    width: 100px;
    text-align: right;
  }
  p::before{
    content: "*";
    color: red;
  }
}
.modelInput{
  margin-bottom: 10px;
}
</style>
